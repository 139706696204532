export default date => {
  let timestamp = Date.parse(date) // Parse the date to timestamp

  let myDate = new Date(timestamp) // Create a Date using the timestamp

  const months = [
    // A 'database' to store the months
    "Ιανουαρίου",
    "Φεβρουαρίου",
    "Μαρτίου",
    "Απριλίου",
    "Μαίου",
    "Ιουνίου",
    "Ιουλίου",
    "Αυγούστου",
    "Σεπτεμβρίου",
    "Οκτωβρίου",
    "Νοεμβρίου",
    "Δεκεμβρίου",
  ]

  return `${myDate.getDate()} ${
    months[myDate.getMonth()]
  } ${myDate.getFullYear()}, ${
    myDate.getHours() < 10 ? "0" : ""
  }${myDate.getHours()}:${
    myDate.getMinutes() < 10 ? "0" : ""
  }${myDate.getMinutes()}`
}
