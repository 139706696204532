import { useStaticQuery, graphql } from "gatsby"

export const useFeaturedImage = () => {
  const { featuredImage } = useStaticQuery(
    graphql`
      query {
        featuredImage: file(relativePath: { eq: "featuredImage.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 550, jpegQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return featuredImage
}
